<template>
  <div>
    <button @click="downloadExcel">Download Excel</button>
  </div>
</template>

<script>
import XLSX from 'xlsx';

export default {
  methods: {
    downloadExcel() {
      const data = [
        { Name: 'John Doe', Age: 25, City: 'New York' },
        { Name: 'Jane Smith', Age: 30, City: 'London' },
        { Name: 'Bob Johnson', Age: 35, City: 'Paris' }
      ];

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Convert the workbook to a binary string
      const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary string to a Blob
      const buffer = new ArrayBuffer(excelBinaryString.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < excelBinaryString.length; i++) {
        view[i] = excelBinaryString.charCodeAt(i) & 0xFF;
      }
      const blob = new Blob([buffer], { type: 'application/octet-stream' });

      // Create a temporary link and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'data.xlsx';
      link.click();
    }
  }
};
</script>
